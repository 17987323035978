import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@/components/Button";
import { CameraSlash, QrCode, X } from "@phosphor-icons/react";
import QrScanner from "qr-scanner";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { getConnectorIdFromEVSEId } from "@/utils/chargeStation";
import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { useRouter } from "next/router";
import { useSerialAndConnector } from "@/utils/hooks";
import { DD_ACTION, DD_GLOBAL_PROPERTY } from "@/utils/datadog";
import { toast } from "@/components/toasts/state";
import PlaceNotFoundFeedback from "./PlaceNotFoundFeedback";
import InvalidEvseError from "./InvalidEvseError";
import Image from "next/image";

const notUsedUrls = [
  "linktr.ee",
  "www.lichtblick.de",
  "www.eneco-emobility.com",
  "www.chargeit-mobility.com",
];
interface Props {
  onScanStart?: () => void;
  onScanEnd?: () => void;
}

export default function QRScanner({ onScanStart, onScanEnd }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const previousScannedResult = useRef<string | null>(null);
  const scanTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const { push, locale } = useRouter();
  const { serial, connectorId } = useSerialAndConnector();
  const showInfo = useMemo(
    () => !isCameraOpen && (!serial || !connectorId),
    [isCameraOpen, serial, connectorId]
  );
  const qrScanner = useRef<QrScanner | null>(null);

  const onScan = async () => {
    if (!videoRef.current) return;

    onScanStart?.();
    setIsCameraOpen(true);
    datadogRum.addAction(DD_ACTION.QR_SCAN_START);

    qrScanner.current = new QrScanner(
      videoRef.current,
      async (result) => {
        // clear previous timeout
        if (scanTimeout.current) {
          clearTimeout(scanTimeout.current);
        }
        // add a timeout so that the previousEvseId is reset after 1 second
        // in order to let the user scan the code again if needed
        scanTimeout.current = setTimeout(() => {
          previousScannedResult.current = null;
        }, 500);

        if (result.data === previousScannedResult.current) {
          return;
        }

        previousScannedResult.current = result.data;

        if (notUsedUrls.find((url) => result.data.includes(url))) {
          datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_WRONG_URL, {
            scannedUrl: result.data,
          });
          toast.error({
            message: t("scanError.wrongQrCode"),
          });
          return;
        }

        try {
          const evseId = new URL(result.data).searchParams.get("evseid");

          if (evseId) {
            const connectorId = getConnectorIdFromEVSEId(evseId);

            try {
              const { data: stationId } = await axios.get<string>(
                `/api/station/getSerialFromEvseId`,
                { params: { evseId } }
              );

              previousScannedResult.current = null;
              setIsCameraOpen(false);
              qrScanner.current?.destroy();

              const query = new URLSearchParams();
              query.set("id", stationId);
              query.set("s", connectorId);
              push(`/?${query.toString()}`, undefined, {
                locale,
                shallow: true,
              });

              datadogRum.setGlobalContextProperty(
                DD_GLOBAL_PROPERTY.HAS_SCANNED_QR_CODE,
                true
              );

              datadogRum.addAction(DD_ACTION.QR_SCAN_SUCCESS, {
                stationId,
                connectorId,
              });

              onScanEnd?.();
            } catch (e: any) {
              if (e?.response?.data === "EVSE_OPERATOR_NOT_SERVED") {
                datadogRum.addAction(
                  DD_ACTION.QR_SCAN_ERROR_EVSE_OPERATOR_NOT_SERVED,
                  { scannedUrl: result.data }
                );

                toast.error({
                  message: t("scanError.notServedEVSE"),
                });
              } else if (e?.response?.data === "PLACE_NOT_FOUND") {
                datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_PLACE_NOT_FOUND, {
                  scannedUrl: result.data,
                });

                toast.error({
                  isStatic: true,
                  isDismissable: false,
                  message: t("scanError.placeNotFound.message"),
                  children: (onDismiss) => (
                    <PlaceNotFoundFeedback
                      scannedEvseId={evseId}
                      onDismiss={onDismiss}
                    />
                  ),
                });
                setIsCameraOpen(false);
                qrScanner.current?.destroy();
              } else if (e?.response?.data === "EVSEID_INVALID") {
                datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_EVSEID_INVALID, {
                  scannedUrl: result.data,
                });

                toast.error({
                  message: t("scanError.invalidEVSE"),
                  children: (onDismiss) => (
                    <InvalidEvseError onDismiss={onDismiss} />
                  ),
                  isStatic: true,
                });
                setIsCameraOpen(false);
                qrScanner.current?.destroy();
              } else {
                datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_UNKNOWN, {
                  data: e?.response?.data,
                  scannedUrl: result.data,
                });

                toast.error({
                  message: t("scanError.otherError"),
                });
              }
            }
          } else {
            datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_EVSE_NOT_IN_URL, {
              scannedUrl: result.data,
            });

            toast.error({
              message: t("scanError.noEVSE"),
            });

            previousScannedResult.current = null;
          }
        } catch (e) {
          toast.error({
            message: t("scanError.noEVSE"),
          });
        }
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        onDecodeError: (e) => {},
      }
    );

    try {
      await qrScanner.current?.start();
    } catch (e) {
      toast.error({
        icon: CameraSlash,
        message: t("cameraNotAvailable"),
        isStatic: true,
      });
      console.error(e);
    }
  };

  const onCancel = useCallback(() => {
    setIsCameraOpen(false);
    qrScanner.current?.destroy();
    onScanEnd?.();
  }, [onScanEnd]);

  useEffect(() => {
    QrScanner.hasCamera().then(
      (hasCamera) =>
        !hasCamera &&
        toast.error({
          icon: CameraSlash,
          message: t("cameraNotAvailable"),
          isStatic: true,
        })
    );
  }, [t]);

  return (
    <div
      className={clsx("w-full relative flex flex-col text-center gap-10 pb-0", {
        "pt-4 items-center": showInfo,
        "items-start": !showInfo,
      })}
    >
      {showInfo && (
        <div className="flex flex-col gap-6">
          <h3 className="text-3xl font-header">{t("scanHeadline")}</h3>

          <div className="font-medium">
            <h5 className="px-10 mb-1">{t("scanDesc1")}</h5>
            <h5>{t("scanDesc2")}</h5>
          </div>
        </div>
      )}

      {showInfo && (
        <div
          className={clsx(
            "flex flex-col items-center gap-4 rounded-2xl p-8 pb-2 shadow-md border border-gray-50"
          )}
        >
          <Image
            width={249}
            height={126}
            src="/images/code.png"
            alt="intercharge-code-example"
            className="max-h-24 w-auto object-contain"
          />

          <div className="text-gray-500">{t("example")}</div>
        </div>
      )}

      <Button
        disabled={isCameraOpen}
        className={clsx({
          "self-stretch justify-center": showInfo,
          hidden: isCameraOpen,
        })}
        onClick={onScan}
      >
        <QrCode className="w-6 h-6" /> {t("scanQRCode")}
      </Button>

      <Button
        color="transparent"
        className={clsx(
          "w-12 h-12 !bg-gray-100 !bg-opacity-80 hover:!bg-opacity-100 active:!bg-gray-300 text-black !rounded-full absolute right-2 top-2 z-10 justify-center !p-0",
          { hidden: !isCameraOpen }
        )}
        onClick={onCancel}
      >
        <X size={24} weight="bold" />
      </Button>

      <video
        className={clsx("w-full rounded-md", {
          hidden: !isCameraOpen,
        })}
        ref={videoRef}
      />
    </div>
  );
}
