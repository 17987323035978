import { i18n } from "next-i18next";

export function formatNumber(
  value: number | string,
  maximumFractionDigits?: number,
  unit?: string
) {
  let numberValue = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(numberValue)) {
    numberValue = 0;
  }

  return (
    new Intl.NumberFormat(i18n?.language, {
      maximumFractionDigits,
      minimumFractionDigits: 0,
      minimumIntegerDigits: 1,
    }).format(numberValue) + (unit ? ` ${unit}` : "")
  );
}
