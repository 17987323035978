import { ConnectorStatus } from "./types/ConnectorStatus.enum";
import { ChargePointState } from "./types/ChargePointState.enum";

export function mapApiConnectorStatus(status: ChargePointState | undefined) {
  switch (status) {
    case ChargePointState.OCCUPIED:
    case ChargePointState.AVAILABLE: {
      return ConnectorStatus.AVAILABLE;
    }
    case ChargePointState.UNAVAILABLE:
    case ChargePointState.OFFLINE:
    case ChargePointState.UNKNOWN:
    case ChargePointState.FAULTED:
    case ChargePointState.OTHER: {
      return ConnectorStatus.UNAVAILABLE;
    }
    case ChargePointState.FOREIGN_LOADING:
    case ChargePointState.RESERVED: {
      return ConnectorStatus.OCCUPIED;
    }
    case ChargePointState.LOADING: {
      return ConnectorStatus.CHARGING;
    }
    case ChargePointState.FINISHING: {
      return ConnectorStatus.FINISHING;
    }
    default: {
      return ConnectorStatus.UNAVAILABLE;
    }
  }
}

export function getConnectorIdFromEVSEId(evseId: string) {
  return evseId.slice(-1);
}
