import axios from "axios";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "next-i18next";
import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import { Coins, Info, Lightning } from "@phosphor-icons/react";
import Button from "@/components/Button";
import { ChargingInfo as ChargingInfoType } from "@/utils/types/ChargingInfo";
import Spinner from "@/components/Spinner";
import { formatNumber } from "@/utils/formatNumber";
import { useCookies } from "react-cookie";
import clsx from "clsx";
import BatteryIndicator from "./BatteryIndicator";
import Badge from "@/components/Badge";
import Message from "@/components/toasts/Message";
import { datadogRum } from "@datadog/browser-rum";
import { DD_ACTION, DD_GLOBAL_PROPERTY } from "@/utils/datadog";
import StopChargingConfirmation from "./StopChargingConfirmation";

interface Props {
  stationId?: string;
  connectorId: string | null;
  connectorStatus: ConnectorStatus | null;
  setConnectorStatus: (status: ConnectorStatus) => void;
  isFree: boolean;
}

export default function ChargingInfo({
  connectorStatus,
  setConnectorStatus,
  stationId,
  connectorId,
  isFree,
}: Props) {
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "chargingProcessId",
    "hasFinishedCharging",
  ]);
  const { chargingProcessId, hasFinishedCharging } = useMemo(
    () => cookies,
    [cookies]
  );

  const [chargingInfo, setChargingInfo] = useState<ChargingInfoType | null>(
    null
  );
  const [askingForStoppingConfirmation, setAskingForStoppingConfirmation] =
    useState(false);
  const [stoppingCharging, setStoppingCharging] = useState(false);
  let interval = useRef<NodeJS.Timeout | null>(null);

  const isCharging =
    chargingProcessId !== undefined &&
    connectorStatus === ConnectorStatus.CHARGING;

  const showInvoiceMessage = chargingInfo?.chargingCosts != 0;

  const getChargingInfo = useCallback(async () => {
    let chargingInfo: ChargingInfoType,
      status: ConnectorStatus | null = connectorStatus;

    try {
      const { data: newConnectorStatus } = await axios.get<ConnectorStatus>(
        "/api/station/getStatus",
        {
          params: { stationId, connectorId },
          headers: { "Cache-Control": "no-cache" },
        }
      );
      status = newConnectorStatus;
    } catch (e: any) {
      console.error(
        `CHARGING_STATION_STATUS_ERROR ${JSON.stringify(e?.response?.data)}`
      );
    }

    try {
      const { data: energyData } = await axios.get<ChargingInfoType>(
        "/api/station/getChargingInfo",
        {
          params: { chargingProcessId },
          headers: { "Cache-Control": "no-cache" },
        }
      );

      chargingInfo = energyData;

      if (
        ([ConnectorStatus.CHARGING, ConnectorStatus.FINISHING, null].includes(
          connectorStatus
        ) &&
          ![ConnectorStatus.FINISHING, ConnectorStatus.CHARGING, null].includes(
            status
          )) ||
        hasFinishedCharging
      ) {
        const { data: chargingCosts } = await axios.get<number>(
          "/api/station/getChargingCosts",
          { params: { chargingProcessId } }
        );

        chargingInfo.chargingCosts = chargingCosts;
        setCookie("hasFinishedCharging", true);
        datadogRum.setGlobalContextProperty(
          DD_GLOBAL_PROPERTY.CHARGING_PROCESS_ID,
          undefined
        );
        setStoppingCharging(false);
      }

      setChargingInfo(chargingInfo);
    } catch (e) {
      console.error("Couldn't get charging info", e);
      console.dir(e);
    }
    if (status) {
      setConnectorStatus(status);
    }
  }, [chargingProcessId, connectorStatus, hasFinishedCharging]);

  const onStopCharging = async () => {
    setStoppingCharging(true);
    setAskingForStoppingConfirmation(false);

    try {
      const response = await axios.post("/api/station/stopCharging", {
        chargingStationId: stationId,
        connectorId,
        chargingProcessId,
      });
      if (response.status !== 200) {
        setStoppingCharging(false);
      } else {
        datadogRum.addAction(DD_ACTION.STOP_CHARGING, {
          stationId,
          connectorId,
          chargingProcessId,
          isFree,
        });
      }
    } catch (e) {
      console.error("Could not stop charging", e);
      setStoppingCharging(false);
    }
  };

  const onDismissChargingInfo = () => {
    removeCookie("chargingProcessId");
    removeCookie("hasFinishedCharging");
    setChargingInfo(null);
  };

  const onAskForStopConfirmation = () => {
    setAskingForStoppingConfirmation(true);
  };

  useEffect(() => {
    if (chargingProcessId && !hasFinishedCharging) {
      getChargingInfo();
      interval.current = setInterval(() => {
        getChargingInfo();
      }, 10 * 1000); // 10 seconds
    }

    if (hasFinishedCharging) {
      getChargingInfo();
    }
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [getChargingInfo, chargingProcessId, hasFinishedCharging]);

  return (
    <div
      className={clsx(
        "flex flex-col self-stretch gap-4 border border-t-0 rounded-b-lg border-gray-200 p-4",
        { hidden: chargingProcessId === undefined }
      )}
    >
      <div className="flex items-center justify-between gap-2 pt-2">
        <h3 className="font-bold text-xl">{t("chargingInfo")}</h3>

        <span
          className={clsx("flex gap-1 items-center pr-2", {
            "text-medium-green": hasFinishedCharging,
            "text-medium-aqua": !hasFinishedCharging,
          })}
        >
          {hasFinishedCharging && (
            <Badge color="gray">{t("finishedCharging")}</Badge>
          )}
          <BatteryIndicator
            animate={
              !hasFinishedCharging &&
              connectorStatus !== ConnectorStatus.FINISHING
            }
          />
        </span>
      </div>

      {chargingInfo ? (
        <div className="flex gap-6">
          <InfoColumn
            label={t("chargedEnergy")}
            value={formatNumber(chargingInfo.energyConsumption, 2, "kWh")}
            icon={<Lightning className="shrink-0" weight="bold" size={20} />}
          />

          {chargingInfo.chargingCosts !== undefined && (
            <InfoColumn
              label={t("chargingCost")}
              value={
                chargingInfo.chargingCosts
                  ? formatNumber(chargingInfo.chargingCosts, 2, "€")
                  : t("nocosts")
              }
              icon={<Coins className="shrink-0" weight="bold" size={20} />}
            />
          )}

          <InfoColumn />
        </div>
      ) : (
        <div className="flex-1 flex justify-center">
          <Spinner />
        </div>
      )}

      {hasFinishedCharging ? (
        <>
          {showInvoiceMessage && (
            <Message
              color="gray"
              message={t("infoMessageFinished")}
              icon={Info}
            />
          )}

          <Button
            color="orange"
            className="justify-center"
            onClick={onDismissChargingInfo}
          >
            {t("actionButtonLabelDismiss")}
          </Button>
        </>
      ) : connectorStatus === ConnectorStatus.FINISHING ? (
        <Message
          message={
            t("infoMessageFinishing") +
            (!isFree ? " " + t("infoMessageFinishingInvoice") : "")
          }
          icon={Info}
        />
      ) : (
        <Button
          disabled={!isCharging || stoppingCharging}
          color="red"
          className="justify-center"
          onClick={onAskForStopConfirmation}
        >
          {isCharging ? (
            <>
              {stoppingCharging && <Spinner />}
              {t("actionButtonLabelStop")}
            </>
          ) : (
            <>{t("preparingCharging")}...</>
          )}
        </Button>
      )}

      <StopChargingConfirmation
        open={askingForStoppingConfirmation}
        onClose={() => setAskingForStoppingConfirmation(false)}
        onConfirm={onStopCharging}
      />
    </div>
  );
}

interface InfoColumnProps {
  label?: ReactNode;
  value?: ReactNode;
  icon?: ReactNode;
}

const InfoColumn = ({ label, value, icon }: InfoColumnProps) =>
  !value ? (
    <div className="flex-1" />
  ) : (
    <div className="flex items-center gap-2">
      {icon}

      <div className="flex flex-col">
        <div className="text-sm font-medium text-gray-400">{label}</div>
        <div className="text-base font-semibold">{value}</div>
      </div>
    </div>
  );
