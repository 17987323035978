import Modal from "../Modal";
import { useTranslation } from "next-i18next";
import Button from "../Button";
import { useCookies } from "react-cookie";
import { useEffect, useMemo, useState } from "react";
import { initDatadog } from "@/utils/datadog";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ArrowLeft, CaretDown } from "@phosphor-icons/react";
import Datadog from "./Datadog";

interface Props {}

export default function ConsentManagement({}: Props) {
  const [isMounted, setIsMounted] = useState(false); // to overcome hydration error
  const { t } = useTranslation("consent");
  const [{ hasConsent, consentChosen }, setCookie] = useCookies([
    "hasConsent",
    "consentChosen",
  ]);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const open = useMemo(() => consentChosen !== true, [consentChosen]);

  const onChooseConsent = (accept: boolean) => {
    setCookie("consentChosen", true);
    setCookie("hasConsent", accept);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (hasConsent) {
      initDatadog();
    }
  }, [hasConsent]);

  if (!isMounted) return null;

  return (
    <Modal
      open={open}
      onClose={() => {}}
      className="px-4 py-6 w-full max-w-lg gap-4"
    >
      {!settingsOpen ? (
        <>
          <div className="font-header text-2xl leading-7">{t("title")}</div>

          <div className="flex flex-col gap-2 text-sm text-gray-600">
            <span>{t("desc1")}</span>
            <span>{t("desc2")}</span>

            <div className="flex items-center gap-3 pt-2">
              <a
                className="self-start underline text-black font-medium"
                href="https://www.lichtblick.de/impressum/"
                target="_blank"
              >
                {t("imprint")}
              </a>

              <a
                className="self-start underline text-black font-medium"
                href="https://www.lichtblick.de/datenschutz/"
                target="_blank"
              >
                {t("privacy")}
              </a>

              <button
                className="self-start underline text-black font-medium"
                onClick={() => setSettingsOpen(true)}
              >
                {t("settings")}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center gap-4">
            <button
              className="self-start font-medium flex items-center gap-2 border border-gray-200 px-2 py-1 rounded-lg bg-white hover:bg-gray-100 active:bg-gray-200 transition-colors text-sm"
              onClick={() => setSettingsOpen(false)}
            >
              <ArrowLeft /> {t("back")}
            </button>

            <div className="text-2xl font-header">{t("settings")}</div>
          </div>

          <div className="overflow-y-auto flex-1 pb-2 -mx-4 px-4">
            <Disclosure as="div" className="group w-full flex flex-col text-sm">
              <DisclosureButton className="flex items-center justify-between gap-2 p-2 border rounded-lg text-left bg-white hover:bg-gray-100 active:bg-gray-200 transition-colors group-data-[open]:rounded-b-none font-medium">
                Datadog
                <CaretDown
                  weight="bold"
                  className="transform group-data-[open]:rotate-180 transition-transform"
                />
              </DisclosureButton>

              <DisclosurePanel className="px-2 py-3 border border-t-0 rounded-b-lg text-xs">
                <Datadog />
              </DisclosurePanel>
            </Disclosure>
          </div>
        </>
      )}

      <div className="flex items-center flex-wrap gap-4 pt-4">
        <Button
          color="gray"
          className="flex-1 justify-center"
          onClick={() => onChooseConsent(false)}
        >
          {t("decline")}
        </Button>
        <Button
          className="flex-1 justify-center"
          onClick={() => onChooseConsent(true)}
        >
          {t("accept")}
        </Button>
      </div>
    </Modal>
  );
}
