import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  PaymentElement,
  ExpressCheckoutElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Spinner from "@/components/Spinner";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { StripeElementLocale } from "@stripe/stripe-js";
import { CheckCircle, Info } from "@phosphor-icons/react";
import Button from "@/components/Button";
import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
import { useCookies } from "react-cookie";
import ChargingStartingIndicator from "./ChargingStartingIndicator";
import { DD_ACTION, DD_ERROR, DD_GLOBAL_PROPERTY } from "@/utils/datadog";
import {
  ChargingProcessStatus,
  ChargingProcessStatusResponse,
} from "@/utils/types/ChargingProcessStatus";
import { toast } from "@/components/toasts/state";

interface Props {
  amount: number;
  isFree: boolean;
  identification: string;
  connectorId?: string;
  stationId?: string;
  setAmount: (amount: number) => void;
}

export default function PayementForm({
  amount,
  isFree,
  identification,
  connectorId,
  stationId,
  setAmount,
}: Props) {
  const { i18n, t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [cookies, setCookie, deleteCookie] = useCookies([
    "paymentIntentId",
    "clientSecret",
    "chargingProcessId",
  ]);
  const emailRef = useRef<HTMLInputElement>(null);
  const [emailError, setEmailError] = useState(false);
  const [isStartingCharging, setIsStartingCharging] = useState(false);
  const startingChargingInterval = useRef<NodeJS.Timeout | null>(null);

  const isDisabled = useMemo(
    () => isStartingCharging || ((!stripe || !elements || !email) && !isFree),
    [isStartingCharging, stripe, elements, email, isFree]
  );

  async function onStartChargingTimeout(
    paymentIntentId?: string,
    paymentMethodId?: string,
    email?: string
  ) {
    datadogRum.addAction(DD_ACTION.START_CHARGING_TIMEOUT, {
      stationId,
      connectorId,
      paymentIntentId,
    });

    startingChargingInterval.current = setInterval(async () => {
      let shouldStopPolling = true,
        isStartingCharging = false;

      try {
        const { data } = await axios.get<ChargingProcessStatusResponse>(
          "/api/station/getPaymentIntentStatus",
          {
            params: { paymentIntentId },
            headers: { "Cache-Control": "no-cache" },
          }
        );

        switch (data.chargingSessionStatus) {
          case ChargingProcessStatus.CHARGING_PROCESS_STARTED: {
            datadogRum.addAction(DD_ACTION.START_CHARGING, {
              stationId,
              connectorId,
              chargingProcessId: cookies.chargingProcessId,
            });
            setCookie("chargingProcessId", data.chargingProcessId, {
              maxAge: 24 * 60 * 60, // 1 day in seconds
            });
            deleteCookie("paymentIntentId");
            deleteCookie("clientSecret");
            datadogRum.setGlobalContextProperty(
              DD_GLOBAL_PROPERTY.CHARGING_PROCESS_ID,
              data.chargingProcessId
            );
            toast.success({
              message: isFree
                ? t("feedbackAfterStartingFree")
                : t("feedbackAfterStarting"),
              icon: CheckCircle,
            });
            break;
          }

          case ChargingProcessStatus.PAYMENT_INTENT_INVALID: {
            deleteCookie("paymentIntentId");
            deleteCookie("clientSecret");
            toast.error({
              message: t("error.charging"),
            });
            break;
          }

          case ChargingProcessStatus.PAYMENT_INTENT_VALID: {
            onStartCharging(paymentIntentId, paymentMethodId, email);
            isStartingCharging = true;
            break;
          }

          default: {
            shouldStopPolling = false;
            isStartingCharging = true;
            break;
          }
        }
      } catch (e: any) {
        console.dir(e);
        datadogRum.addError(DD_ERROR.GET_PAYMENT_INTENT_STATUS, {
          data: e?.response?.data,
          status: e?.response?.status,
        });
      } finally {
        if (shouldStopPolling) {
          startingChargingInterval.current &&
            clearInterval(startingChargingInterval.current);
        }

        setIsStartingCharging(isStartingCharging);
      }
    }, 3000);
  }

  async function onStartCharging(
    paymentIntentId?: string,
    paymentMethodId?: string,
    email?: string
  ) {
    setIsStartingCharging(true);
    datadogRum.setGlobalContextProperty(
      DD_GLOBAL_PROPERTY.PAYMENT_INTENT_ID,
      paymentIntentId
    );

    try {
      const { data: chargingData } = await axios.post(
        "/api/station/startCharging",
        {
          userIdentification: identification,
          chargingStationId: stationId,
          connectorId,
          paymentIntentId,
          amount,
          email,
          paymentMethodId,
        }
      );

      if (chargingData && chargingData.chargingProcessId) {
        datadogRum.addAction(DD_ACTION.START_CHARGING, {
          stationId,
          connectorId,
          chargingProcessId: chargingData.chargingProcessId,
        });

        setCookie("chargingProcessId", chargingData.chargingProcessId, {
          maxAge: 24 * 60 * 60, // 1 day in seconds
        });

        datadogRum.setGlobalContextProperty(
          DD_GLOBAL_PROPERTY.CHARGING_PROCESS_ID,
          chargingData.chargingProcessId
        );

        setIsStartingCharging(false);

        toast.success({
          message: isFree
            ? t("feedbackAfterStartingFree")
            : t("feedbackAfterStarting"),
          icon: CheckCircle,
        });
        return true;
      } else {
        if (chargingData?.chargingProcessResult === "EXTENDED_AUTH_REQUIRED") {
          return "EXTENDED_AUTH_REQUIRED";
        } else {
          toast.error({
            message: t("error.charging"),
          });
        }
        setIsStartingCharging(false);
        return false;
      }
    } catch (e: any) {
      console.dir(e);
      console.error(
        `START_CHARGING_ERROR: status ${
          e?.response?.status || "UNKNOWN"
        } - ${JSON.stringify(e?.response?.data || {})}`,
        e
      );

      let isStartingCharging = false;

      if (e?.response?.status) {
        switch (e.response.status) {
          case 400: {
            toast.error({
              message: t("error.terminalError", {
                error_code: e.response?.data?.code,
              }),
            });
            break;
          }

          case 402: {
            if (e.response?.data?.code) {
              switch (e.response.data.code) {
                case "card_declined": {
                  switch (e.response.data?.errorReason) {
                    case "insufficient_funds":
                      toast.error({
                        message: t("error.insufficientFunds"),
                      });
                      break;

                    case "card_velocity_exceeded":
                      toast.error({
                        message: t("error.cardVelocityExceeded"),
                      });
                      break;

                    default:
                      toast.error({
                        message: t("error.cardDeclined"),
                      });
                      break;
                  }
                  break;
                }

                case "expired_card":
                  toast.error({
                    message: t("error.expiredCard"),
                  });
                  break;

                case "incorrect_cvc":
                  toast.error({
                    message: t("error.incorrectCVC"),
                  });
                  break;

                case "processing_error":
                  toast.error({
                    message: t("error.payment"),
                  });
                  break;

                default:
                  toast.error({
                    message: t("error.payment"),
                  });
                  break;
              }
            } else {
              toast.error({
                message: t("error.payment"),
              });
            }
            break;
          }

          case 403: {
            toast.error({
              message: t("error.identificationForbidden"),
            });
            break;
          }

          case 404: {
            toast.error({
              message: t("error.charging"),
            });
            break;
          }

          case 409: {
            toast.error({
              message: t("error.startedByDifferentUser"),
            });
            break;
          }

          case 502:
          case 504: {
            onStartChargingTimeout(paymentIntentId, paymentMethodId, email);
            isStartingCharging = true;
            break;
          }

          default: {
            toast.error({
              message: t("error.charging"),
            });
            break;
          }
        }
      }

      setIsStartingCharging(isStartingCharging);
      return false;
    }
  }

  const onSubmitForm = async (e?: FormEvent) => {
    e?.preventDefault();

    setIsStartingCharging(true);

    if (isFree) {
      const startChargingResult = await onStartCharging();

      if (startChargingResult === true) {
        setIsStartingCharging(false);
      }
      return;
    }

    if (!email) {
      setEmailError(true);
      return;
    }

    if (!stripe || !elements || !email) {
      return;
    }
    elements.submit();

    let paymentIntentId = cookies.paymentIntentId,
      clientSecret = cookies.clientSecret,
      pm,
      startChargingResult;

    pm = await stripe.createPaymentMethod({
      elements,
      params: { billing_details: { email } },
    });

    if (pm.error) {
      toast.error({
        message: t("error.payment"),
      });
    } else {
      if (!paymentIntentId) {
        try {
          const { data } = await axios.post("/api/createPaymentIntent", {
            amount,
            email,
          });
          paymentIntentId = data.paymentIntentId;
          clientSecret = data.clientSecret;
          setCookie("paymentIntentId", paymentIntentId, {
            maxAge: 24 * 60 * 60, // 1 day in seconds
          });
          setCookie("clientSecret", clientSecret, {
            maxAge: 24 * 60 * 60, // 1 day in seconds
          });
        } catch (e) {
          toast.error({
            message: t("error.payment"),
          });
          setIsStartingCharging(false);
          console.error(e);
          return;
        }
      }

      try {
        startChargingResult = await onStartCharging(
          paymentIntentId,
          pm.paymentMethod.id,
          email
        );

        if (startChargingResult === "EXTENDED_AUTH_REQUIRED") {
          const res = await stripe.handleNextAction({ clientSecret });

          if (res.error) {
            toast.error({
              message: t("error.extendedAuthError"),
            });
            setIsStartingCharging(false);
          } else if (res.paymentIntent) {
            startChargingResult = await onStartCharging(
              res.paymentIntent.id,
              pm.paymentMethod.id,
              email
            );
          }
        }

        if (startChargingResult === true) {
          deleteCookie("paymentIntentId");
          deleteCookie("clientSecret");
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (startChargingResult === true) {
      setIsStartingCharging(false);
    }
  };

  useEffect(() => {
    elements?.update({ locale: i18n.language as StripeElementLocale });
  }, [elements, i18n]);

  useEffect(() => {
    if (emailError) {
      emailRef.current?.focus();
    }
  }, [emailError]);

  return (
    <form
      onSubmit={onSubmitForm}
      className="flex flex-col gap-8 md:gap-9 w-full"
    >
      <div
        className={clsx("flex flex-col gap-8 md:gap-9", {
          hidden: isFree || isStartingCharging,
        })}
      >
        <div className="flex-1 flex flex-col gap-1">
          <h3 className="font-semibold text-lg md:text-xl">
            {t("emailLabel")}
          </h3>

          <input
            ref={emailRef}
            className={clsx(
              "border border-gray-300 rounded-lg px-3 h-12 font-sans text-base outline-none transition-colors",
              {
                "!border-rose-600 ring-1 ring-rose-600": emailError,
                "focus:border-blue-600 focus:ring-1 focus:ring-blue-600":
                  !emailError,
              }
            )}
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e) => {
              setEmailError(false);
              datadogRum.setUserProperty("email", e.target.value);
              setEmail(e.target.value);
            }}
            placeholder={t("emailPlaceholder")}
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="font-semibold flex justify-between items-center text-lg md:text-xl">
            {t("amount.label")}

            <div className="p-1 relative group cursor-pointer">
              <Info className="w-7 h-7" />

              <p className="hidden group-hover:block absolute right-0 top-full w-max max-w-xs md:max-w-md bg-white rounded-xl px-4 py-3 border border-gray-300 shadow-lg text-base font-normal z-30">
                {t("amount.info")}
              </p>
            </div>
          </h3>

          <div className="flex items-center text-base">
            <Button
              type="button"
              onClick={() => setAmount(50)}
              color="transparent"
              className={clsx(
                "border border-r-0 border-gray-300 rounded-r-none flex-1 justify-center",
                {
                  "text-blue-600 !border-blue-600 border-2 border-r-2 font-semibold":
                    amount === 50,
                }
              )}
            >
              50 €
            </Button>

            <Button
              type="button"
              onClick={() => setAmount(80)}
              color="transparent"
              className={clsx(
                "border border-l-0 border-gray-300 rounded-l-none flex-1 justify-center",
                {
                  "text-blue-600 !border-blue-600 border-2 border-l-2 font-semibold":
                    amount === 80,
                }
              )}
            >
              80 €
            </Button>
          </div>
        </div>

        <div className="flex flex-col">
          <h3 className="font-semibold text-lg md:text-xl">
            {t("paymentHeadline")}
          </h3>

          {!elements && (
            <div className="self-stretch flex justify-center py-3">
              <Spinner />
            </div>
          )}

          <ExpressCheckoutElement
            className="mb-2"
            onLoadError={(e) => {
              console.error("Express checkout load error", e);
            }}
            onClick={(e) => {
              if (!email) {
                setEmailError(true);
                return;
              } else {
                e.resolve();
              }
            }}
            onConfirm={() => {
              onSubmitForm();
            }}
          />

          <PaymentElement options={{ layout: "tabs" }} />
        </div>
      </div>

      <Button
        disabled={isDisabled}
        className={clsx("justify-center", { hidden: isStartingCharging })}
      >
        {t("chargeButtonLabel")}
      </Button>

      {isStartingCharging && <ChargingStartingIndicator />}
    </form>
  );
}
