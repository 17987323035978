import { Phone } from "@phosphor-icons/react";
import { useTranslation } from "next-i18next";

export default function SupportInfoBar() {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 flex justify-center w-full text-xs text-gray-500 py-1 px-4">
      <div className="md:max-w-lg w-full flex justify-end gap-6">
        <a
          className="flex items-center gap-1 underline"
          href="tel:0800 0670 000"
        >
          <Phone weight="bold" />
          0800 0670 000
        </a>

        <a
          className="flex items-center gap-1 underline"
          href="https://www.lichtblick.de/impressum/"
          target="_blank"
        >
          {t("imprint")}
        </a>
      </div>
    </div>
  );
}
