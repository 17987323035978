import {
  BatteryFull,
  BatteryHigh,
  BatteryLow,
  BatteryMedium,
  IconProps,
} from "@phosphor-icons/react";
import { HTMLProps, useEffect, useMemo, useState } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  animate?: boolean;
}

export default function BatteryIndicator({ animate = true }: Props) {
  const [charge, setCharge] = useState<number>(1);

  const icon = useMemo(() => {
    const props: IconProps = {
      size: 44,
    };

    if (!animate) {
      return <BatteryFull {...props} />;
    }

    switch (charge) {
      case 1:
        return <BatteryLow {...props} />;
      case 2:
        return <BatteryMedium {...props} />;
      case 3:
        return <BatteryHigh {...props} />;
      case 4:
        return <BatteryFull {...props} />;

      default:
        return <BatteryLow {...props} />;
    }
  }, [charge, animate]);

  useEffect(() => {
    if (animate) {
      const interval = setInterval(() => {
        setCharge((c) => (c === 4 ? 1 : c + 1));
      }, 800);

      return () => {
        clearInterval(interval);
      };
    }
  }, [animate]);

  return <div>{icon}</div>;
}
