import Button from "@/components/Button";
import { DD_ACTION } from "@/utils/datadog";
import { datadogRum } from "@datadog/browser-rum";
import { useTranslation } from "next-i18next";

interface Props {
  onDismiss: () => void;
}

export default function InvalidEvseError({ onDismiss }: Props) {
  const { t } = useTranslation();

  const onOpenApp = () => {
    const appleStoreURL = "https://apps.apple.com/app/id1524423977";
    const googleStoreURL =
      "https://play.google.com/store/apps/details?id=com.chargeitmobility.easy.chargeit";
    const isAppleDevice = /iPad|iPhone|iPod|Macintosh/.test(
      navigator.userAgent
    );
    const url = isAppleDevice ? appleStoreURL : googleStoreURL;
    datadogRum.addAction(DD_ACTION.QR_SCAN_OPENED_APP, {
      platform: isAppleDevice ? "iOS" : "Android",
    });
    window.open(url, "_blank")?.focus();
  };

  return (
    <div className="flex flex-col gap-4 mb-2">
      <div className="w-full flex flex-col gap-1">{t("scanError.openApp")}</div>

      <div className="flex gap-2">
        <Button onClick={onOpenApp} color="red" className="text-white flex-1">
          {t("scanError.toApp")}
        </Button>

        <Button
          onClick={onDismiss}
          color="transparent"
          className="text-dark-berry justify-center"
        >
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
}
