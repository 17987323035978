import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import { ConnectorType } from "@/utils/types/ConnectorType.enum";
import { Station } from "@/utils/types/Station";
import {
  ChargingStation,
  Coins,
  Lightning,
  MapPin,
  Plug,
  Warning,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { useMemo } from "react";
import CHADEMO from "./connectorTypeIcons/CHADEMO";
import CCS from "./connectorTypeIcons/CCS";
import Type2 from "./connectorTypeIcons/Type2";
import SCHUKO from "./connectorTypeIcons/SCHUKO";
import { useTranslation } from "next-i18next";
import Message from "@/components/toasts/Message";
import StatusDisplayLabel from "./StatusDisplayLabel";
import { Connector } from "@/utils/types/Connector";
import { useCookies } from "react-cookie";
import WarningIndicator from "./plugInIllustrations/Warning";
import PlugCar from "./plugInIllustrations/PlugCar";

interface Props {
  station: Station | null;
  connector: Connector | null;
}

export default function StationInfo({ station, connector }: Props) {
  const { t } = useTranslation();
  const [cookies] = useCookies(["chargingProcessId"]);
  const { chargingProcessId } = cookies;

  const isCharging = !!chargingProcessId;
  const isOccupied = [
    ConnectorStatus.OCCUPIED,
    ConnectorStatus.UNAVAILABLE,
  ].includes(connector?.status || ConnectorStatus.UNAVAILABLE);

  const connectorTypeIcon = useMemo(() => {
    switch (connector?.connectorType) {
      case ConnectorType.TYP2:
      case ConnectorType.TYP2_CABLE:
        return <Type2 className="size-6" />;
      case ConnectorType.CCS:
        return <CCS className="size-6" />;
      case ConnectorType.CHAdeMO:
        return <CHADEMO className="size-6" />;
      case ConnectorType.SCHUKO:
        return <SCHUKO className="size-6" />;

      default:
        return null;
    }
  }, [connector?.connectorType]);

  const showConnectorSide = useMemo(
    () =>
      station
        ? station.numberOfEVSEs > 1 && station.serialNumber.startsWith("ALF_")
        : false,
    [station]
  );

  if (!station || !connector) return null;

  return (
    <div className="w-full flex flex-col">
      <div
        className={clsx("bg-gray-50 border border-gray-200 rounded-lg", {
          "rounded-b-none": !isOccupied,
          "mb-6 md:mb-9": isOccupied && !isCharging,
        })}
      >
        <div className="p-4 md:pt-6 gap-3 md:gap-5 text-sm md:text-base flex flex-col">
          <div className="flex items-center justify-between gap-8">
            <StatusDisplayLabel
              status={connector.status}
              className="shrink-0"
            />

            {Boolean(station.operator.shortName) && (
              <div className="text-sm font-semibold text-gray-500 text-end">
                {t("operatedBy")} {station.operator.shortName}
              </div>
            )}
          </div>

          <h1 className="text-2xl md:text-3xl font-semibold flex gap-3">
            <ChargingStation
              className="text-3xl md:text-4xl shrink-0"
              weight="bold"
            />
            {station.name}
          </h1>

          <div className="flex flex-col gap-2">
            <div className="flex gap-1 font-medium text-sm text-gray-400">
              <MapPin weight="bold" className="shrink-0 text-base mt-0.5" />
              {station.address.street}, {station.address.postalCode}{" "}
              {station.address.city}
            </div>

            <div className="font-medium flex gap-1.5 text-sm text-gray-400">
              <Coins weight="bold" className="shrink-0 text-base mt-0.5" />
              {connector.priceInfo.isFree ? (
                <span>{t("tariffIsFree")}</span>
              ) : (
                <span>
                  {t("tariffLabel") + ": "}
                  {connector.priceInfo.tariffName}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="flex h-11 border-t border-gray-200 font-semibold text-sm">
          <div className="border-r flex-1 flex items-center justify-center gap-1.5">
            {connectorTypeIcon}
            {connector.connectorType}
          </div>

          <div className="flex-1 flex items-center justify-center gap-1.5">
            <Lightning size={17} weight="bold" className="shrink-0" />
            {connector.loadCapacity} kW
          </div>

          <div
            className={clsx(
              "flex-1 flex items-center justify-center gap-1.5 border-l border-gray-200",
              { hidden: !showConnectorSide }
            )}
          >
            <Plug size={17} weight="bold" className="shrink-0" />

            <span className="md:hidden">
              {connector.connectorId === "1"
                ? "L"
                : connector.connectorId === "2"
                ? "R"
                : connector.connectorId}
            </span>

            <span className="hidden md:inline">
              {connector.connectorId === "1"
                ? t("connector.locationLeft")
                : connector.connectorId === "2"
                ? t("connector.locationRight")
                : connector.connectorId}
            </span>
          </div>
        </div>
      </div>

      {isOccupied && !isCharging && (
        <Message
          message={t("infoMessageOccupied")}
          color="yellow"
          icon={Warning}
        />
      )}

      {!isCharging && !isOccupied && (
        <div className="flex items-center p-4 gap-4 justify-between border border-t-0 border-light-smoke bg-light-smoke rounded-b-lg overflow-hidden relative">
          <div className="flex items-center gap-4">
            <WarningIndicator />

            <span className="font-semibold text-sm md:text-base">
              {t("pluginInfoText")}
            </span>
          </div>

          <PlugCar />
        </div>
      )}
    </div>
  );
}
