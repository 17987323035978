import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import Badge from "@/components/Badge";

interface Props {
  status: ConnectorStatus | null;
  className?: string;
}

export default function StatusDisplayLabel({ status, className }: Props) {
  const { t } = useTranslation();

  const statusDisplayLabel = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
        return t("connector.state.available");
      case ConnectorStatus.CHARGING:
        return t("connector.state.charging");
      case ConnectorStatus.FINISHING:
        return t("connector.state.finishing");
      case ConnectorStatus.OCCUPIED:
        return t("connector.state.occupied");
      case ConnectorStatus.UNAVAILABLE:
      default:
        return t("connector.state.unavailable");
    }
  }, [status, t]);

  const color = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
        return "green";
      case ConnectorStatus.UNAVAILABLE:
      case ConnectorStatus.CHARGING:
      case ConnectorStatus.OCCUPIED:
        return "red";
      case ConnectorStatus.FINISHING:
        return "orange";
      default:
        return undefined;
    }
  }, [status]);

  return (
    status && (
      <Badge className={className} color={color}>
        {statusDisplayLabel}
      </Badge>
    )
  );
}
