export enum ChargePointState {
  OFFLINE = "OFFLINE",
  UNKNOWN = "UNKNOWN",
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  FAULTED = "FAULTED",
  RESERVED = "RESERVED",
  OCCUPIED = "OCCUPIED",
  LOADING = "LOADING",
  FOREIGN_LOADING = "FOREIGN_LOADING",
  FINISHING = "FINISHING",
  OTHER = "OTHER",
}
