import { SVGProps } from "react";

export default function CCS(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="svg2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 35.2"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n\t.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:3.2;}\n\t.st1{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.2;}\n"
        }
      </style>
      <g id="layer1">
        <ellipse id="ellipse4216" cx={19.6} cy={7.5} rx={2} ry={2} />
        <ellipse
          id="ellipse4216_00000088111602150882366850000014835598767496096920_"
          cx={23.1}
          cy={12.5}
          rx={2}
          ry={2}
        />
        <ellipse id="ellipse4218" cx={12.5} cy={7.5} rx={2} ry={2} />
        <ellipse
          id="ellipse4218_00000147221531179765679950000009385568115522351278_"
          cx={15.9}
          cy={12.5}
          rx={2}
          ry={2}
        />
        <ellipse
          id="ellipse4218_00000042716614448213346700000003160974067343255995_"
          cx={12.2}
          cy={18}
          rx={2}
          ry={2}
        />
        <ellipse
          id="ellipse4218_00000169558399489611419240000014603703316313933701_"
          cx={19.6}
          cy={18}
          rx={2}
          ry={2}
        />
        <ellipse
          id="ellipse4218_00000115475988480972299190000010832016976542448038_"
          cx={8.7}
          cy={12.5}
          rx={2}
          ry={2}
        />
        <path
          id="path4231"
          className="st0"
          d="M21.8,2.4c0.7,0,1.5,0.3,2,0.6c0.4,0.3,2.8,3,3.7,5.5c0.3,0.8,0.5,1.7,0.6,2.8 c0.1,1.1,0,2.6-0.3,3.8c-0.3,1.1-0.7,2.1-1.2,2.9c-0.5,0.8-0.7,1.2-1.3,1.8c-0.5,0.6-1.3,1.4-2,1.9c-0.7,0.5-1.6,1.1-2.4,1.5 c-0.9,0.4-2.9,0.9-2.9,0.9s-2.7,0.4-4.9-0.4c-0.8-0.3-1.5-0.4-2.4-0.9c-1.2-0.6-2.4-1.4-3.3-2.4c-1.3-1.4-2.3-3-2.9-4.9 c-0.5-1.4-0.6-2.9-0.6-4.4c0-1.1,0.3-2.3,0.7-3.3C6,4.9,8.1,3,8.3,2.9c0.5-0.4,1.2-0.5,1.8-0.5C10.2,2.4,21.8,2.4,21.8,2.4z"
        />
        <ellipse id="path4151" cx={12.2} cy={28.6} rx={2} ry={2} />
        <ellipse id="ellipse4204" cx={19.7} cy={28.6} rx={2} ry={2} />
        <path
          id="rect4235"
          className="st1"
          d="M11.6,24.3h8.8c2.4,0,4.3,1.9,4.3,4.3l0,0c0,2.4-1.9,4.3-4.3,4.3h-8.8c-2.4,0-4.3-1.9-4.3-4.3 l0,0C7.3,26.2,9.2,24.3,11.6,24.3z"
        />
      </g>
    </svg>
  );
}
