import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "next-i18next";
import { toast } from "@/components/toasts/state";

async function getToken(uuid: string) {
  try {
    const { data } = await axios.get("/api/user/login", {
      params: { uuid },
    });

    if (data && data.token) {
      return data.token;
    }
  } catch (e) {
    throw e;
  }
}

export function useAuth() {
  const { t } = useTranslation();
  const [token, setToken] = useState<string>();
  const [cookies, setCookie] = useCookies(["userId"]);
  const { userId } = cookies;
  const [identification, setIdentification] = useState(userId);

  const getUser = useCallback(async () => {
    if (!token) return;

    try {
      const { data } = await axios.get("/api/user/getUser", {
        params: { token },
      });

      const identification = data?.identifications?.[0];

      if (identification) {
        setIdentification(identification.id);
        datadogRum.setUserProperty("identification", identification);
      }
    } catch (error: any) {
      toast.error({ message: t("error.loginUnsuccessful") });
      console.error(error);
    }
  }, [t, token]);

  const login = useCallback(async () => {
    let uuid = userId;

    try {
      if (!uuid) {
        uuid = crypto.randomUUID();
        setCookie("userId", uuid);

        datadogRum.setUser({
          userId: uuid,
        });
      }

      const token = await getToken(uuid);

      if (token) {
        setToken(token);
      }
    } catch (error: any) {
      toast.error({ message: t("error.loginUnsuccessful") });
      console.error(error);
    }
  }, [t, setCookie, userId]);

  useEffect(() => {
    login();
  }, [login]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    axios.interceptors.response.clear();
    if (userId) {
      axios.interceptors.response.use(undefined, async (error) => {
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }

        const originalRequestConfig = error.config;

        const token = await getToken(userId);
        setToken(token);

        if (originalRequestConfig.params) {
          originalRequestConfig.params.token = token;
        } else {
          originalRequestConfig.params = { token };
        }

        return axios.request(originalRequestConfig);
      });
    }
  }, [userId]);

  useEffect(() => {
    axios.interceptors.request.clear();
    if (token) {
      axios.interceptors.request.use((config) => {
        if (config.params) {
          config.params.token = token;
        } else {
          config.params = { token };
        }
        return config;
      });
    }
  }, [token]);

  return { token, identification };
}
