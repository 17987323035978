import { useCallback, useEffect, useMemo, useState } from "react";
import { Station } from "@/utils/types/Station";
import axios from "axios";
import Spinner from "@/components/Spinner";
import { Elements } from "@stripe/react-stripe-js";
import { getStripeElementsConfig, stripePromise } from "@/utils/stripe";
import CheckoutForm from "@/features/PaymentForm/PaymentForm";
import { WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "next-i18next";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import QRScanner from "@/features/QRScanner/QRScanner";
import { datadogRum } from "@datadog/browser-rum";
import { useSerialAndConnector } from "@/utils/hooks";
import ChargingInfo from "@/features/ChargingProcess/ChargingInfo";
import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import { useCookies } from "react-cookie";
import StationInfo from "@/features/StationInfo/StationInfo";
import { Connector } from "@/utils/types/Connector";
import ConsentManagement from "@/components/consentManagement/ConsentManagement";
import SupportInfoBar from "@/components/SupportInfoBar";
import { useAuth } from "@/utils/auth";
import { toast } from "@/components/toasts/state";
import Message from "@/components/toasts/Message";
import { DD_GLOBAL_PROPERTY } from "@/utils/datadog";

export default function Home() {
  const { t } = useTranslation();
  const [cookies] = useCookies(["chargingProcessId"]);
  const { chargingProcessId } = cookies;

  const [loadingStation, setLoadingStation] = useState(false);
  const [station, setStation] = useState<Station | null>(null);
  const [connector, setConnector] = useState<Connector | null>(null);
  const [amount, setAmount] = useState(50);

  const [isScanning, setIsScanning] = useState(false);
  const { token, identification } = useAuth();

  const { serial, connectorId } = useSerialAndConnector();

  const isFree = Boolean(connector?.priceInfo.isFree);

  const showPaymentForm = useMemo(
    () =>
      (station?.directPaymentEnabled || isFree) &&
      !chargingProcessId &&
      connector &&
      connector?.status === ConnectorStatus.AVAILABLE,
    [chargingProcessId, connector, isFree, station]
  );

  const getStationData = useCallback(async () => {
    if (!token || !serial || !connectorId) return;

    setLoadingStation(true);

    try {
      const response = await axios.get<{
        station: Station;
        connector: Connector;
      }>("/api/station/getStationBySerial", {
        params: { serial, connectorId },
      });

      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.STATION,
        response.data.station
      );
      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.CONNECTOR,
        response.data.connector
      );
      setStation(response.data.station);
      setConnector(response.data.connector);
    } catch (error: any) {
      toast.error({ message: t("error.stationData") });
      console.error(error);
    } finally {
      setLoadingStation(false);
    }
  }, [serial, connectorId, token, t]);

  const setConnectorStatus = useCallback((status: ConnectorStatus | null) => {
    setConnector((connector) => {
      if (status !== connector?.status) {
        datadogRum.setGlobalContextProperty(
          DD_GLOBAL_PROPERTY.CONNECTOR_STATUS,
          status
        );
      }
      return status && connector && status !== connector?.status
        ? { ...connector, status }
        : connector;
    });
  }, []);

  useEffect(() => {
    getStationData();
  }, [getStationData]);

  return (
    <div className="h-full flex flex-col items-center overflow-y-auto">
      <SupportInfoBar />

      <div className="flex-1 flex flex-col items-center gap-6 md:gap-9 max-w-full md:max-w-lg w-full pt-6 pb-16 md:pt-8 md:pb-8 px-4 md:px-0 rounded-lg">
        <QRScanner
          onScanStart={() => {
            setIsScanning(true);
          }}
          onScanEnd={() => {
            setIsScanning(false);
          }}
        />

        {isScanning ? null : (
          <>
            {loadingStation && <Spinner />}

            {station && (
              <div className="self-stretch">
                <StationInfo station={station} connector={connector} />

                <ChargingInfo
                  stationId={station?.id}
                  connectorId={connectorId}
                  connectorStatus={connector?.status ?? null}
                  setConnectorStatus={setConnectorStatus}
                  isFree={isFree}
                />
              </div>
            )}

            {showPaymentForm && (
              <Elements
                options={getStripeElementsConfig(amount)}
                stripe={stripePromise}
              >
                <CheckoutForm
                  isFree={isFree}
                  amount={amount}
                  identification={identification}
                  connectorId={connector?.connectorId}
                  stationId={station?.id}
                  setAmount={setAmount}
                />
              </Elements>
            )}

            {station !== null && !station?.directPaymentEnabled && (
              <Message
                message={t("error.directPaymentNotPossible")}
                color="red"
                icon={WarningCircle}
              />
            )}
          </>
        )}
      </div>

      <ConsentManagement />
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "de", ["common", "consent"])),
    },
  };
};
