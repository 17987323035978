import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

export function useSerialAndConnector() {
  const params = useSearchParams();
  return useMemo(
    () => ({
      serial: params.get("id"),
      connectorId: params.get("s"),
    }),
    [params]
  );
}
